import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "about-the-apis",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#about-the-apis",
        "aria-label": "about the apis permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`About the APIs`}</h1>
    <p>{`Tyro Health supports Medicare Bulk Bill, Patient Claim and DVA claims processing via APIs for select partners. Our APIs connect directly to Services Australia for claim processing. These APIs are built on HTTP REST with JSON and require authentication using the authorization header.`}</p>
    <p>{`There is a set of common API responses all our APIs can respond with. To help with troubleshooting, some 400 responses will have additional information. These are documented separately in each API under custom 400 errors.`}</p>
    <p>{`APIs will not respond to HTTP (port 80) requests. Requests must be over HTTPS/TLS (port 443).`}</p>
    <p>{`Note that geo-IP controls may be in place - requests should originate from Australian IP addresses.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      